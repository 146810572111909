@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.color-nav {
  background-color: #5dbcd2;
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 0px;
  padding-bottom: 5px;
}

.logo-button {
  margin-right: 7px;
  margin-bottom: 4px;
}

.nav-brand {
  color: white;
  font-family: "Open Sans", serif;
  font-size: 30px;
  font-weight: 700;
}

.nav-item {
  color: white;
  font-family: "Open Sans", serif;
  font-size: 22px;
  font-weight: 600;
}

#container {
  width: 100%;
  height: 100%;
  position: fixed;
}

#map {
  width: 100%;
  height: 93%;
  position: absolute;
  z-index: -1;
}

#map-menu {
  background-color: white;
  top: 165px;
  left: 10px;
  padding: 17px;
  position: absolute;
}

#cities-search {
  position: absolute;
  top: 70px;
  right: 64px;
}

#branding img {
  position: fixed;
  right: 0px;
  bottom: 0px;
  max-height: 30px;
  background-color: #ececec;
  border-radius: 4px;
}

.btn-group {
  width: 100%;
}

.dropdown .btn {
  background: white;
  color: black;
  font-family: "Open Sans", serif;
  font-weight: 500;
  border-width: 0;
  width: 225px;
  height: 41px;
}

.dropdown .btn:hover {
  background: white;
  color: #000;
}
.dropdown .btn:active {
  background: white;
  color: #000;
}
.dropdown .btn:focus {
  background: white;
  color: #000;
}

.dropdown-menu {
  max-height: 400px;
  width: 225px;
  overflow-y: scroll;
}

.loading-center {
  text-align: center;
}

.loading-spinner {
  color: #5dbcd2;
  width: 60px;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 15px;
}

h3 {
  margin-bottom: 15px;
  font-family: "Open Sans", serif;
  font-size: 24px;
  font-weight: 700;
}

h4 {
  margin-top: 15px;
  margin-bottom: 7px;
  font-family: "Open Sans", serif;
  font-weight: 500;
  font-size: 18px;
}

.loading-text {
  font-family: "Open Sans", serif;
  font-weight: 500;
  font-size: 24px;
  margin-left: 15px;
  margin-right: 15px;
}

.loading-subtext {
  font-family: "Open Sans", serif;
  font-weight: 300;
  font-size: 16px;
  margin-left: 15px;
  margin-right: 15px;
}

.linked-activity {
  color: #fc4c02;
  text-decoration: underline;
  cursor: pointer;
}

.black-header {
  text-align: center;
  color: black;
  font-size: 46px;
  font-weight: 800;
  margin-top: 10px;
  margin-bottom: 10px;
}

.stats-description {
  color: black;
  font-size: 27px;
  font-weight: 700;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}

.stats-header {
  color: black;
  font-size: 24px;
  font-weight: 700;
  margin-top: 15px;
  margin-left: 20px;
}

.button-position {
  margin-left: 20px;
}

.img-stats {
  display: block;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  width: 15%;
}

.bootstrap-table {
  margin-left: 20px;
  margin-right: 20px;
}

.react-bootstrap-table th {
  border: black solid 1.5px !important;
}

.react-bootstrap-table td {
  border: black solid 1.5px !important;
}

table thead .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

table thead .dropup .caret,
table thead .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.react-bootstrap-table th .order > .dropdown > .caret {
  color: black;
}

.react-bootstrap-table th .order > .dropup > .caret {
  color: black;
}

.pagination > li > a {
  background-color: #e8e1eb;
  color: black;
  border-color: black;
}

.radar-chart {
  width: 55%;
  margin-bottom: 20px;
  display: inline-block;
}

.day-table {
  display: inline-block;
  width: 42%;
}

.stats-chart {
  margin: 20px;
}

.doughnut-chart {
  width: 49%;
  padding-top: 0px;
  margin-bottom: 20px;
  display: inline-block;
}

.heart-rate-table {
  display: inline-block;
  width: 49%;
}

.csv-btn {
  background-color: #5dbcd2;
  border-color: transparent;
  border-radius: 4px;
  margin-bottom: 15px;
  color: white;
  font-weight: 600;
  font-size: 19px;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 2px;
  padding-bottom: 2px;
}

body {
  background-color: #5dbcd2;
  font-family: "Open Sans", serif;
}

h1 {
  text-align: center;
  color: white;
  font-size: 56px;
  font-weight: 800;
  margin-top: 10px;
}

h5 {
  text-align: center;
  color: white;
  margin-top: 45px;
  font-size: 28px;
  font-weight: 600;
}

h6 {
  margin-top: 40px;
  margin-bottom: 0px;
  text-align: center;
  color: white;
  font-size: 20px;
}

.demo-text {
  margin-top: 30px;
  margin-bottom: 10px;
  text-align: center;
  color: white;
  font-size: 18px;
  text-decoration: underline;
}

.img-center {
  display: block;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  width: 20%;
}

.button-center {
  text-align: center;
  margin-top: 30px;
  cursor: pointer;
}

.store-data-button-group {
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px; /* Adjust the spacing between buttons */
  margin-top: 15px; /* Add some top margin to separate buttons from the text */
}

.about-header {
  text-align: center;
  color: black;
  font-size: 46px;
  font-weight: 800;
  margin-top: 20px;
  margin-bottom: 10px;
}

.screenshot {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;
}

.about-description {
  font-size: 19px;
  font-weight: 500;
  margin-top: 25px;
  margin-left: 70px;
  margin-right: 70px;
  text-align: center;
}

.link {
  display: inline-block;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.icon-container {
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  width: 100%;
}

.icon {
  width: 90px;
  height: 90px;
}

.icon-text {
  margin-top: 7px;
  text-align: center;
}

