.about-header {
  text-align: center;
  color: black;
  font-size: 46px;
  font-weight: 800;
  margin-top: 20px;
  margin-bottom: 10px;
}

.screenshot {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;
}

.about-description {
  font-size: 19px;
  font-weight: 500;
  margin-top: 25px;
  margin-left: 70px;
  margin-right: 70px;
  text-align: center;
}

.link {
  display: inline-block;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.icon-container {
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  width: 100%;
}

.icon {
  width: 90px;
  height: 90px;
}

.icon-text {
  margin-top: 7px;
  text-align: center;
}
