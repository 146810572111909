@import url(https://fonts.googleapis.com/css?family=Open+Sans);

#container {
  width: 100%;
  height: 100%;
  position: fixed;
}

#map {
  width: 100%;
  height: 93%;
  position: absolute;
  z-index: -1;
}

#map-menu {
  background-color: white;
  top: 165px;
  left: 10px;
  padding: 17px;
  position: absolute;
}

#cities-search {
  position: absolute;
  top: 70px;
  right: 64px;
}

#branding img {
  position: fixed;
  right: 0px;
  bottom: 0px;
  max-height: 30px;
  background-color: #ececec;
  border-radius: 4px;
}

.btn-group {
  width: 100%;
}

.dropdown .btn {
  background: white;
  color: black;
  font-family: "Open Sans", serif;
  font-weight: 500;
  border-width: 0;
  width: 225px;
  height: 41px;
}

.dropdown .btn:hover {
  background: white;
  color: #000;
}
.dropdown .btn:active {
  background: white;
  color: #000;
}
.dropdown .btn:focus {
  background: white;
  color: #000;
}

.dropdown-menu {
  max-height: 400px;
  width: 225px;
  overflow-y: scroll;
}

.loading-center {
  text-align: center;
}

.loading-spinner {
  color: #5dbcd2;
  width: 60px;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 15px;
}

h3 {
  margin-bottom: 15px;
  font-family: "Open Sans", serif;
  font-size: 24px;
  font-weight: 700;
}

h4 {
  margin-top: 15px;
  margin-bottom: 7px;
  font-family: "Open Sans", serif;
  font-weight: 500;
  font-size: 18px;
}

.loading-text {
  font-family: "Open Sans", serif;
  font-weight: 500;
  font-size: 24px;
  margin-left: 15px;
  margin-right: 15px;
}

.loading-subtext {
  font-family: "Open Sans", serif;
  font-weight: 300;
  font-size: 16px;
  margin-left: 15px;
  margin-right: 15px;
}
